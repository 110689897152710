/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectTrans } from '@containers/App/selectors';
import { I_Province } from '@containers/Home/types';

interface Props {
  provinceSelected: string;
  onChangeProvinceSelected: ({ province, tourSlug }) => void;
  dropdownItems: I_Province[];
}

export const SelectProvince = ({
  dropdownItems = [],
  provinceSelected,
  onChangeProvinceSelected,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchText, setSearchText] = useState('');

  const trans = useSelector(selectTrans);

  const dropdownRef = useRef(null);

  const handleDocumentClick = useCallback((e: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target))
      setIsOpen(false);
  }, []);

  const toggleDropdown = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const handleItemClick = useCallback(
    (item: (typeof dropdownItems)[number]) => {
      setIsOpen(false);
      setSelectedItem(item);
      onChangeProvinceSelected({
        province: item.slug,
        tourSlug: item.projectId,
      });
    },
    [onChangeProvinceSelected],
  );

  const filteredItems = dropdownItems?.filter(item =>
    item.name.toLowerCase().includes(searchText.toLowerCase()),
  );

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (provinceSelected && dropdownItems.length) {
      const province = dropdownItems?.filter(
        item => item.slug === provinceSelected,
      );
      if (province) setSelectedItem(province[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinceSelected, dropdownItems.length]);

  return (
    <>
      <div className="dropdown-container" ref={dropdownRef}>
        <div className="dropdown-header --selectadr" onClick={toggleDropdown}>
          {selectedItem ? selectedItem.name : ''}
          <i className="fa-solid fa-caret-down"></i>
        </div>
        {isOpen && (
          <div className="dropdown-list drop-address">
            <div className="--search">
              <h4 className="--title">
                {trans ? trans['fe.change.province'] : ''}
              </h4>
              <div className="form">
                <div className="form-group">
                  <i className="fa-light fa-magnifying-glass"></i>
                  <input
                    type="text"
                    placeholder={trans ? trans['fe.tour.input.search'] : ''}
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="--listAddress">
              {filteredItems?.map((item, index) => (
                <div
                  className="itemAdr dropdown-item"
                  key={index}
                  onClick={() => handleItemClick(item)}
                >
                  <div className="--imgAdr">
                    <img
                      src={
                        item?.featuredImage?.thumbnail_640_jpg ||
                        item?.featuredImage?.thumbnail_720_jpg ||
                        item?.featuredImage?.default
                      }
                      alt=""
                    />
                  </div>
                  <div className="--txtAdr">
                    <h6 className="--nameAdr">{item?.name}</h6>
                    <div className="--numberAdr">
                      {item?.numberLocation}{' '}
                      {trans ? trans['fe.header.number.location'] : ''}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
